<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        大於的祝福—公益騎士大會師
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="">
        <p class="image_desc">優化騎乘經驗騎行不分族群</p>
        <p class="image_desc">圖片為大於的祝福活動主視覺</p>
        <p class="image_desc">(圖片提供：昇陽自行車)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          以數據改良優化身障協力車/電輔車為主題，召集全台車友，於騎乘過程中搭配數位工具，結合自行車功率器、電輔車傳感器，以及自行車硬體與環境基本資料等，上傳資料串連資料庫累計相關運動數據，並上傳至運動數據公益平台，藉由邀請視障車友一同參與，使數據與公益發揮影響力，倡議促進身障族群運動平權。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          昇陽自行車與台灣適應身體學會聯手舉辦「大於的祝福」公益騎士大會師活動。邀請車友參與活動提供數據，進行騎乘負荷偏好分析，連結人車數據，進而探討騎乘負荷、操作行為，優化騎乘經驗以解決公益族群騎行痛點。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據公益模式</div>
        <div class="content-desc">
          透過數據分析應用升級硬體製造，發展符合公益族群騎行偏好之輔助控制模式，透過偏擺測試生產出更適應視障騎行運動的協力車，達到騎行不分族群，強化身障自行車、電輔車功能，設計最適騎乘車款。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公益展望</div>
        <div class="content-desc">
          昇陽自行車進行產品改良，預計帶動改良電輔車出口產業效益。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11207',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-07.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
